var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h4',{staticClass:"mb-0"},[_vm._v(" Edit Pengaturan Keuangan ")]),_c('validation-observer',{ref:"validUpdate"},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"payment_type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Jenis Pembayaran","label-for":"payment_type","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"payment_type","reduce":function (payment_type) { return payment_type.real_id; },"placeholder":"Pilih Jenis Pembayaran","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listpaymenttype,"label":"name"},model:{value:(_vm.dataStudent.payment_type),callback:function ($$v) {_vm.$set(_vm.dataStudent, "payment_type", $$v)},expression:"dataStudent.payment_type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"academic_year_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tahun","label-for":"academic_year_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"academic_year_id","reduce":function (academic_year_id) { return academic_year_id.real_id; },"placeholder":"Pilih Tahun","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listacademicyear,"label":"name"},model:{value:(_vm.dataStudent.academic_year_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "academic_year_id", $$v)},expression:"dataStudent.academic_year_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('validation-provider',{attrs:{"name":"class_id","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kelas","label-for":"class_id","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"class_id","reduce":function (class_id) { return class_id.real_id; },"placeholder":"Pilih Tingkat","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.listclass,"label":"level_desc"},model:{value:(_vm.dataStudent.class_id),callback:function ($$v) {_vm.$set(_vm.dataStudent, "class_id", $$v)},expression:"dataStudent.class_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])}),_c('b-form-group',{attrs:{"label":"Nominal","label-for":"nominal"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"nominal","state":errors.length > 0 ? false : null,"placeholder":"Nominal","type":"number"},model:{value:(_vm.dataStudent.nominal),callback:function ($$v) {_vm.$set(_vm.dataStudent, "nominal", $$v)},expression:"dataStudent.nominal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Periode","label-for":"period"}},[_c('validation-provider',{attrs:{"name":"period","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"period","reduce":function (period) { return period.text; },"options":_vm.dataPeriode,"label":"text","placeholder":"Pilih Aktif / Tidak Aktif"},model:{value:(_vm.dataStudent.period),callback:function ($$v) {_vm.$set(_vm.dataStudent, "period", $$v)},expression:"dataStudent.period"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-form-group',{attrs:{"label":"Notes","label-for":"notes"}},[_c('validation-provider',{attrs:{"name":"notes"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"notes","state":errors.length > 0 ? false : null,"placeholder":"Notes"},model:{value:(_vm.dataStudent.notes),callback:function ($$v) {_vm.$set(_vm.dataStudent, "notes", $$v)},expression:"dataStudent.notes"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('validation-provider',{attrs:{"name":"Aktif","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Aktif","label-for":"active","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"active","reduce":function (active) { return active.value; },"placeholder":"Pilih Aktif / Tidak Aktif","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.dataIsActive,"label":"text"},model:{value:(_vm.dataStudent.active),callback:function ($$v) {_vm.$set(_vm.dataStudent, "active", $$v)},expression:"dataStudent.active"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-row',{staticClass:"text-right"},[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1",attrs:{"type":"reset","variant":"outline-secondary"},on:{"click":function () { return _vm.$router.go(-1); }}},[_vm._v(" Cancel ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"submit","variant":"primary"}},[_vm._v(" Update ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }